module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"miss poppy's handmade","short_name":"miss poppy's","start_url":"/","background_color":"#cdc1c5","theme_color":"#cdc1c5","display":"standalone","icon":"src/static/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"952d436d9645d2282381f86b4583414e"},
    },{
      plugin: require('../node_modules/@spencerwhyte/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-NBDV44N","cookieName":"gatsby-gdpr-google-tagmanager"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
